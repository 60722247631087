import React from 'react'

import Layout from '../components/layout'


const IndexPage = () => (
  <Layout />
)

export default IndexPage
